import './sentry/instrument';
import '@uturn/design-tokens/base.css';
import './assets/global.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ClerkProvider } from '@clerk/clerk-react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { LicenseManager, ThemeProvider } from '@uturn/ui-kit';
import { PostHogProvider } from 'posthog-js/react';
import App from './app';
import { PostHogConfig } from 'posthog-js';

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
	throw new Error('Missing Publishable Key');
}

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const posthogOptions: Partial<PostHogConfig> = {
	api_host: import.meta.env.VITE_POSTHOG_HOST,
};

createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<PostHogProvider
			apiKey={import.meta.env.VITE_POSTHOG_KEY}
			options={posthogOptions}
		>
			<HelmetProvider>
				<Helmet titleTemplate="%s | Backoffice" title="Backoffice" />
				<ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
					<ThemeProvider defaultMode="light" defaultColor="finance">
						<App />
					</ThemeProvider>
				</ClerkProvider>
			</HelmetProvider>
		</PostHogProvider>
	</StrictMode>,
);
