import {
	AgGridReact,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from '@uturn/ui-kit';
import Table from './table.tsx';
import { useRef } from 'react';
import { GetOrganizationsIdInvoicesInvoiceLines200DataItem } from '@uturn/api/finance/v1';

type Props = {
	organizationId: number;
	defaultPageSize?: number;
};

const InvoiceLines = ({ organizationId, defaultPageSize }: Props) => {
	const ref =
		useRef<AgGridReact<GetOrganizationsIdInvoicesInvoiceLines200DataItem>>(
			null,
		);

	return (
		<Card>
			<CardHeader>
				<CardTitle>Invoice lines</CardTitle>
			</CardHeader>
			<CardContent className="!h-96">
				<Table
					gridRef={ref}
					id={organizationId}
					defaultPageSize={defaultPageSize}
				/>
			</CardContent>
		</Card>
	);
};

export default InvoiceLines;
