import {
	GetFees200DataItemFeesItemTransportType as FeeTransport,
	GetFees200DataItemFeesItemType as FeeType,
	GetFees200DataItemFeesItemUnit as FeeUnit,
	GetFeesDefaults200Data,
	GetFeesDefaults200DataValueItem,
	PutFeesDefaultsIdBodyItem,
	PutFeesDefaultsId500,
	PutFeesDefaultsId400,
	PutFeesDefaultsId403,
} from '@uturn/api/finance/v1';
import {
	CellClassParams,
	CellStyle,
	IRowNode,
	ValueSetterParams,
} from '@uturn/ui-kit';
import { FeeData, FeeClipboard, RowData, RowDataFeesFlat } from './types';
import { type AxiosError } from 'axios';

/** Ready
 */
export const getNewFee = (
	transportType: FeeTransport,
	type: FeeType,
): FeeData => {
	return {
		fee: 0,
		unit: FeeUnit.FLAT,
		transportType,
		type,
		autoUpdate: false,
		feeMin: 0,
		feeMax: undefined,
		index: -1,
		isDirty: false,
	};
};

/** Ready
 */
export const findRowDataFee = (
	data: GetFeesDefaults200Data,
	transportType: FeeTransport,
	type: FeeType,
) => {
	const fees: GetFeesDefaults200DataValueItem[] = data.value;

	if (!fees.length) {
		return getNewFee(transportType, type);
	}

	const index = fees.findIndex(
		(fee) => fee.transportType === transportType && fee.type === type,
	);

	if (index === -1) {
		return getNewFee(transportType, type);
	}

	const value: FeeData = {
		...fees[index],
		index,
		isDirty: false,
	};

	return value;
};

/** Shared
 */
export const getFeeDataColId = (data: GetFeesDefaults200DataValueItem) => {
	return getFeeColId(data.type, data.transportType);
};

/** Shared
[getFeeColId(FeeType.MARKET,   FeeTransport.SHUNT  )]: market_shunt
[getFeeColId(FeeType.MARKET,   FeeTransport.IMPORT )]: market_import
[getFeeColId(FeeType.MARKET,   FeeTransport.EXPORT )]: market_export
[getFeeColId(FeeType.MARKET,   FeeTransport.OTHER  )]: market_other
[getFeeColId(FeeType.CONTRACT, FeeTransport.SHUNT  )]: contract_shunt
[getFeeColId(FeeType.CONTRACT, FeeTransport.IMPORT )]: contract_import
[getFeeColId(FeeType.CONTRACT, FeeTransport.EXPORT )]: contract_export
[getFeeColId(FeeType.CONTRACT, FeeTransport.OTHER  )]: contract_other */
export const getFeeColId = (type: FeeType, transportType: FeeTransport) => {
	return `${type.toLowerCase()}_${transportType.toLowerCase()}` as keyof RowDataFeesFlat;
};

/** Ready
 */
export const cellStyleFee = (params: CellClassParams<RowData, FeeData>) => {
	const {
		// column,
		// colDef,
		// data,
		// node,
		// rowIndex,
		// api,
		// context,
		value,
	} = params;

	const defaultStyle: CellStyle = {
		color: 'black',
		backgroundColor: 'white',
	};

	if (!value) {
		return defaultStyle;
	}

	if (value.error) {
		return {
			color: 'black',
			backgroundColor: '#f18888',
		};
	}

	if (value.isDirty) {
		return {
			color: 'green',
			backgroundColor: '#f0fff2',
		};
	}

	return defaultStyle;
};

/** Ready
 */
export const setRowDataFee = (
	rowNode: IRowNode<RowData> | null,
	colId: keyof RowDataFeesFlat,
	feeData: FeeData,
	option?: { data?: RowData },
) => {
	if (!rowNode) {
		return feeData;
	}

	const rowData = option?.data ?? rowNode.data;

	if (!rowData) {
		return feeData;
	}

	if (!feeData) {
		return feeData;
	}

	const { index } = feeData;
	const newFees = [...rowData.value];
	// TODO: Fix CellEditorFee too?
	if (index === -1) {
		feeData.index = newFees.length;
		newFees.push(feeData);
	} else {
		newFees[index] = feeData;
	}
	const newRow: RowData = { ...rowData, value: newFees, [colId]: feeData };
	rowNode.setData(newRow);
	return feeData;
};

/** Shared
 */
export const valueSetterFee = (params: ValueSetterParams<RowData, FeeData>) => {
	const { column, data, newValue, node } = params;

	if (!newValue) {
		return false;
	}

	const colId = column.getColId() as keyof RowDataFeesFlat;

	// Client side refresh
	// TODO: Change function signature to setRowDataFee to use feeCell?
	setRowDataFee(node, colId, newValue, { data });

	return true;
};

/** Ready
 */
export const getFeeUnit = (unit: FeeUnit, currencySign?: string) => {
	switch (unit) {
		case FeeUnit.PERCENTAGE:
			return '%';
		case FeeUnit.FLAT:
		default:
			return currencySign ?? '€';
	}
};

/** Ready
 */
export const getPrice = (amount: number, currencySign?: string) => {
	if (!amount) {
		return '-';
	}
	return `${currencySign ?? '€'}${amount.toFixed(2)}`;
};

/** Ready
 */
export const valueFormatterFee = (fee: FeeData, currencySign?: string) => {
	const amount = fee.fee ?? 0;

	if (fee.unit === FeeUnit.PERCENTAGE) {
		return `${amount}%`;
	}

	return getPrice(amount, currencySign);
};

/** Ready
 */
export const getSuccessMessage = () => {
	return 'Default fees have been updated';
};

/** Ready
 */
export const getErrorMessage = (
	error: AxiosError<
		PutFeesDefaultsId500 | PutFeesDefaultsId403 | PutFeesDefaultsId400
	>,
) => {
	if (error.response?.data.code === 'EMPTY_BODY') {
		return 'Nothing to update, please review your change';
	}

	if (error.response?.data.code === 'RESOURCE_NOT_FOUND') {
		return 'Fee not found, please review your change';
	}

	return 'A unknown error occured while trying to update default fees';
};

/** NotUsed
 * Prepare fee data to submit for server side update.
 *
 * For now, `Drayage` implies `Import`, `Export` and `Other`.
 * Therefore, if a `Drayage` fee needs updating, then all 3 underlying fees should be updated. */
export const getSubmittedFees = (
	data: RowData,
	oldValue: FeeData,
	clipBoard: FeeClipboard,
) => {
	const result: GetFeesDefaults200DataValueItem[] = [
		{
			...oldValue,
			...clipBoard,
		},
	];

	if (oldValue.transportType === FeeTransport.SHUNT) {
		return result;
	}

	if (oldValue.type === FeeType.CONTRACT) {
		result.push({
			...data.contract_export,
			...clipBoard,
		});

		result.push({
			...data.contract_other,
			...clipBoard,
		});

		return result;
	}

	result.push({
		...data.market_export,
		...clipBoard,
	});

	result.push({
		...data.market_other,
		...clipBoard,
	});

	return result;
};

/** Ready
 */
export const castClipboard = (value: FeeData): FeeClipboard => {
	const { fee, unit, autoUpdate, feeMin, feeMax } = value;
	return {
		fee,
		unit,
		autoUpdate,
		feeMin,
		feeMax,
	};
};

/** Ready
 */
export const castPutFee = (value: FeeData): PutFeesDefaultsIdBodyItem => {
	const { transportType, type, fee, unit, feeMax, feeMin, autoUpdate } = value;
	return {
		transportType,
		type,
		fee,
		unit,
		feeMax,
		feeMin,
		autoUpdate,
	};
};
