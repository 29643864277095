import { type Rules } from 'react-abac';
import {
	Role,
	Roles,
	type RoleType,
	type PermissionType,
} from '@uturn/fastify-auth-internal/auth';

const getRolePermissions = (role: RoleType) => {
	const initialValue: Partial<Record<PermissionType, boolean>> = {};
	return Roles[role].reduce((permissions, permission) => {
		permissions[permission] = true;
		return permissions;
	}, initialValue);
};

const getRules = () => {
	const initialValue: Rules<RoleType, PermissionType, boolean> = {};
	return Object.values(Role).reduce((rules, role) => {
		rules[role] = getRolePermissions(role);
		return rules;
	}, initialValue);
};

export const rules = getRules();

export * from '@uturn/fastify-auth-internal/auth';
