import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import {
	FormItem,
	FormControl,
	FormLabel,
	RadioGroupItem,
} from '@uturn/ui-kit';
import { twMerge } from 'tailwind-merge';

export type FormOptionsType<T> = {
	id: string;
	value: T;
	icon?: IconDefinition;
	label: string;
	description?: string;
	hidden?: boolean;
};

// TODO: re-used from apps/portal/src/modules/shipments/pages/shipment-create/add-form.tsx.
// TODO: Move both to packages/ui-kit.
export function FormRadioGroupHorizontalItem<T>({
	id,
	value,
	label,
	hidden = false,
}: FormOptionsType<T>) {
	if (hidden) return null;
	return (
		<FormItem>
			<FormControl>
				<FormLabel
					htmlFor={id}
					className={twMerge(
						'cursor-pointer w-full py-3 flex justify-center items-center gap-2 rounded-md border-2 border-muted bg-popover',
						'hover:bg-accent hover:text-accent-foreground',
						'peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary',
						'peer-data-[state=checked]:text-primary [&:has([data-state=checked])]:text-primary'
					)}
				>
					<RadioGroupItem
						className={twMerge(
							'peer border-default',
							'peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary',
							'peer-data-[state=checked]:bg-primary [&:has([data-state=checked])]:bg-primary',
							'peer-data-[state=checked]:text-white [&:has([data-state=checked])]:text-white'
						)}
						id={id}
						value={typeof value === 'boolean' ? id : String(value)}
					/>
					{label}
				</FormLabel>
			</FormControl>
		</FormItem>
	);
}
