import {
	GetFees200DataItemFeesItemTransportType as FeeTransport,
	GetFees200DataItemFeesItemType as FeeType,
	getFeesDefaults,
} from '@uturn/api/finance/v1';
import {
	AgGridReact,
	GridOptions,
	IServerSideDatasource,
	ITooltipParams,
} from '@uturn/ui-kit';
import { FC, RefObject, useMemo } from 'react';
import { CellEditorFee, CellRendererFee } from './components/fee/cell';
import {
	findRowDataFee,
	cellStyleFee,
	valueSetterFee,
	valueFormatterFee,
	getPrice,
} from './components/fee/utils';
import { feeTransportTypeLabels, feeTypeLabels } from './components/fee/labels';
import { FeeData, RowData } from './components/fee/types';
import { useAbac } from 'react-abac';
import { Permissions } from '../../../../../abac';

const Table: FC<{
	gridRef: RefObject<AgGridReact<RowData>>;
	setIsDirty: () => void;
}> = ({ gridRef, setIsDirty }) => {
	const { userHasPermissions } = useAbac();

	const serverSideDatasource: IServerSideDatasource = useMemo(
		() => ({
			getRows: async ({ api, success, fail }) => {
				try {
					const { data: defaultFees } = await getFeesDefaults();

					if (!defaultFees.data) {
						api.showNoRowsOverlay();
					} else {
						api.hideOverlay();
					}

					const rowData: RowData[] = [
						{
							...defaultFees.data,
							market_shunt: findRowDataFee(
								defaultFees.data,
								FeeTransport.SHUNT,
								FeeType.MARKET,
							),
							market_import: findRowDataFee(
								defaultFees.data,
								FeeTransport.IMPORT,
								FeeType.MARKET,
							),
							market_export: findRowDataFee(
								defaultFees.data,
								FeeTransport.EXPORT,
								FeeType.MARKET,
							),
							market_other: findRowDataFee(
								defaultFees.data,
								FeeTransport.OTHER,
								FeeType.MARKET,
							),
							contract_shunt: findRowDataFee(
								defaultFees.data,
								FeeTransport.SHUNT,
								FeeType.CONTRACT,
							),
							contract_import: findRowDataFee(
								defaultFees.data,
								FeeTransport.IMPORT,
								FeeType.CONTRACT,
							),
							contract_export: findRowDataFee(
								defaultFees.data,
								FeeTransport.EXPORT,
								FeeType.CONTRACT,
							),
							contract_other: findRowDataFee(
								defaultFees.data,
								FeeTransport.OTHER,
								FeeType.CONTRACT,
							),
						},
					];

					success({
						rowData,
						rowCount: rowData.length,
					});
				} catch (err) {
					console.error(err);
					fail();
				}
			},
		}),
		[],
	);

	const gridOptions: GridOptions<RowData> = {
		// Column options
		enableBrowserTooltips: true,
		tooltipShowDelay: 0,
		tooltipTrigger: 'hover',
		tooltipMouseTrack: true,
		tooltipInteraction: true,
		suppressContextMenu: true,
		stopEditingWhenCellsLoseFocus: true,
		suppressClipboardPaste: true, // NOTE: columnTypes.fee.suppressPaste already takes care of that.
		columnTypes: {
			fee: {
				suppressFillHandle: true,
				suppressPaste: true,
				floatingFilter: false,
				editable: userHasPermissions(Permissions.Fees.Defaults.Update),
				sortable: true,
				cellStyle: cellStyleFee,
				valueSetter: valueSetterFee,
				cellRenderer: CellRendererFee,
				cellEditor: CellEditorFee,
				cellEditorParams: { setIsDirty },
				cellEditorPopup: true,
				cellEditorPopupPosition: 'under',
				lockVisible: true,
				headerTooltip: 'Sorting will sort flat and percentage fees separately.',
				tooltipValueGetter: (params: ITooltipParams<RowData, FeeData>) => {
					const { value } = params;

					if (!value) {
						return '';
					}

					const tooltips = [`Fee: ${valueFormatterFee(value)}.`];

					if (value.unit === 'PERCENTAGE') {
						if (value.feeMin > 0) {
							tooltips.push(`Cannot be lower than: ${getPrice(value.feeMin)}.`);
						}
						if (value.feeMax && value.feeMax > 0) {
							tooltips.push(
								`Cannot be higher than: ${getPrice(value.feeMax)}.`,
							);
						}
					}

					tooltips.push(
						value.autoUpdate
							? 'Will be affected next time default fees change.'
							: 'Will "NOT" be affected next time default fees change.',
					);

					return tooltips.join('\n');
				},
			},
		},
		defaultColDef: {
			flex: 1,
			floatingFilter: false,
			editable: false,
			suppressFloatingFilterButton: true,
			suppressHeaderMenuButton: true,
			suppressHeaderContextMenu: true,
			suppressMovable: true,
			suppressFillHandle: true,
			enableCellChangeFlash: true,
		},
		columnDefs: [
			{
				headerName: 'Settings',
				children: [
					{
						headerName: 'ID',
						field: 'id',
						sortable: false,
						hide: true,
						suppressFiltersToolPanel: true,
					},
					{
						headerName: 'Key',
						field: 'key',
						sortable: false,
						hide: true,
						suppressFiltersToolPanel: true,
					},
				],
			},
			{
				headerName: feeTypeLabels[FeeType.MARKET],
				children: [
					{
						headerName: feeTransportTypeLabels[FeeTransport.SHUNT],
						field: 'market_shunt',
						type: 'fee',
					},
					{
						headerName: feeTransportTypeLabels[FeeTransport.IMPORT],
						field: 'market_import',
						type: 'fee',
					},
				],
			},
			{
				headerName: feeTypeLabels[FeeType.CONTRACT],
				children: [
					{
						headerName: feeTransportTypeLabels[FeeTransport.SHUNT],
						field: 'contract_shunt',
						type: 'fee',
					},
					{
						headerName: feeTransportTypeLabels[FeeTransport.IMPORT],
						field: 'contract_import',
						type: 'fee',
					},
				],
			},
		],

		// Row options
		getRowId: (row) => row.data.id.toString(),

		// Server side options
		rowModelType: 'serverSide',
		serverSideDatasource,

		// Sidepanel options
		sideBar: false,

		// Clipboard options
		suppressCutToClipboard: true,

		// Pagination options
		pagination: false,
	};

	return (
		<>
			<AgGridReact
				ref={gridRef}
				{...gridOptions}
				className="ag-theme-quartz align-baseline z-0"
			/>
		</>
	);
};

export default Table;
