import {
	GetFees200DataItemFeesItemTransportType,
	GetFees200DataItemFeesItemType,
	GetFees200DataItemFeesItemUnit,
} from '@uturn/api/finance/v1';
import { z } from 'zod';

// TODO: Add relevant validations to make the API safer.
export const formSchema = z
	.object({
		id: z.number(),
		organizationId: z.number(),
		fee: z.coerce.number().positive(),
		unit: z.nativeEnum(GetFees200DataItemFeesItemUnit),
		transportType: z.nativeEnum(GetFees200DataItemFeesItemTransportType),
		type: z.nativeEnum(GetFees200DataItemFeesItemType),
		feeMin: z.coerce.number().nonnegative(),
		feeMax: z.coerce.number().nonnegative().optional(),
		autoUpdate: z.boolean(), // z.string().transform((value) => value === 'true'),
		updatedAt: z.string().optional(),
		createdAt: z.string(),
		index: z.number(),
		isDirty: z.boolean(),
	})
	.superRefine((val, ctx) => {
		if (val.unit === 'PERCENTAGE') {
			if (val.fee > 100) {
				ctx.addIssue({
					code: z.ZodIssueCode.too_big,
					maximum: 100,
					type: 'number',
					inclusive: true,
					path: ['fee'],
					message: 'Percentage cannot be greater than 100%',
				});
			}
			if (val.feeMax && val.feeMax < val.feeMin) {
				ctx.addIssue({
					code: z.ZodIssueCode.too_small,
					minimum: val.feeMin,
					inclusive: true,
					type: 'number',
					path: ['feeMax'],
				});
			}
		}
	});

export type FormValues = z.infer<typeof formSchema>; // or GetFees200DataItemFeesItem;

export const feeClipboardSchema = z.object({
	fee: z.coerce.number().positive(),
	unit: z.nativeEnum(GetFees200DataItemFeesItemUnit),
	feeMin: z.coerce.number().nonnegative(),
	feeMax: z.coerce.number().nonnegative().optional(),
	autoUpdate: z.boolean(), // z.string().transform((value) => value === 'true'),
});
