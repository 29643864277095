import React from 'react';
import queryClient from '../query/query-client';
import { QueryClientProvider } from '@tanstack/react-query';
import { useAuth } from '@clerk/clerk-react';
import { configureBackofficeApiV1, configureFinanceApiV1 } from '@uturn/api';
import { InternalAxiosRequestConfig } from 'axios';

const useConfigureHttpClient = () => {
	const { getToken, orgSlug } = useAuth();

	configureBackofficeApiV1({
		baseUrl: `${import.meta.env.VITE_BACKOFFICE_API_URL}/v1`,
		interceptors: {
			request: [
				async (conf: InternalAxiosRequestConfig) => {
					const token = await getToken();
					conf.headers.Authorization = `Bearer ${token}`;
					conf.headers['org-slug'] = orgSlug;

					return conf;
				},
			],
		},
	});

	configureFinanceApiV1({
		baseUrl: `${import.meta.env.VITE_FINANCE_API_URL}/v1`,
		interceptors: {
			request: [
				async (conf: InternalAxiosRequestConfig) => {
					const token = await getToken();
					conf.headers.Authorization = `Bearer ${token}`;

					return conf;
				},
			],
		},
	});
};

const HttpClientProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	useConfigureHttpClient();

	return (
		<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
	);
};

export default HttpClientProvider;
