import {
	GetFees200DataItemFeesItemTransportType as FeeTransportType,
	GetFees200DataItemFeesItemType as FeeType,
	GetFees200DataItemFeesItemUnit as FeeUnit,
} from '@uturn/api/finance/v1';

/** NotUsed
 */
export const feeTransportTypeLabels: Record<FeeTransportType, string> = {
	[FeeTransportType.SHUNT]: 'Shunt',
	[FeeTransportType.IMPORT]: 'Drayage',
	[FeeTransportType.EXPORT]: 'Drayage',
	[FeeTransportType.OTHER]: 'Drayage',
};

/** NotUsed
 */
export const feeTypeLabels: Record<FeeType, string> = {
	[FeeType.MARKET]: 'Market business',
	[FeeType.CONTRACT]: 'Contract business',
};

/** NotUsed
 */
export const feeUnitLabels: Record<FeeUnit, string> = {
	[FeeUnit.FLAT]: 'Flat',
	[FeeUnit.PERCENTAGE]: 'Percentage',
};
