import { useGetOrganizationsIdGeneralInfo } from '@uturn/api/backoffice/v1';
import { Card, CardContent, CardHeader, CardTitle } from '@uturn/ui-kit';
import { DescriptiveList, DescriptiveListItem } from '../lists/descriptive.tsx';
import { GenericUtils } from '@uturn/utilities/browser';

type Props = {
	organizationId: number;
};

const GeneralInfo = ({ organizationId }: Props) => {
	const { data: generalInfo } = useGetOrganizationsIdGeneralInfo(
		organizationId,
		{
			query: {
				suspense: true,
				select: (data) => data.data.data,
			},
		},
	);

	const currencyNames = new Intl.DisplayNames(['en'], { type: 'currency' });

	const getCurrencySymbol = (code: string) => {
		const format = new Intl.NumberFormat('en-uk', {
			style: 'currency',
			currency: code.toUpperCase(),
		});
		return format.formatToParts(1).find((x) => x.type === 'currency')?.value;
	};

	return (
		<Card>
			<CardHeader>
				<CardTitle>General information</CardTitle>
			</CardHeader>
			<CardContent>
				<DescriptiveList>
					<DescriptiveListItem
						label="Organisation ID"
						helpText="Internal use only"
						allowCopy
					>
						{generalInfo?.id}
					</DescriptiveListItem>
					<DescriptiveListItem
						label="Organisation number"
						helpText="Customer number"
						allowCopy
					>
						{generalInfo?.orgNumber}
					</DescriptiveListItem>
					<DescriptiveListItem
						label="Organisation name"
						allowCopy={!!generalInfo?.name}
					>
						{generalInfo?.name}
					</DescriptiveListItem>
					<DescriptiveListItem label="Organisation status">
						{GenericUtils.capitalizeFirstLetter(generalInfo?.status || '')}
					</DescriptiveListItem>
					<DescriptiveListItem label="Organisation type">
						{GenericUtils.capitalizeFirstLetter(generalInfo?.type || '')}
					</DescriptiveListItem>
					<DescriptiveListItem label="Organisation currency">
						{currencyNames.of(generalInfo?.currency ?? '')} (
						{getCurrencySymbol(generalInfo?.currency ?? '')})
					</DescriptiveListItem>
					<DescriptiveListItem
						label="Registration number"
						allowCopy={!!generalInfo?.registrationNumber}
						helpText="The registration/CoC number of the company in their country of origin"
					>
						{generalInfo?.registrationNumber}
					</DescriptiveListItem>
				</DescriptiveList>
			</CardContent>
		</Card>
	);
};

export default GeneralInfo;
