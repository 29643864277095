import {
	getFees,
	GetFees200DataItemFeesItemTransportType as FeeTransport,
	GetFees200DataItemFeesItemType as FeeType,
	usePostFees,
	usePutFees,
	PutFees400,
	PutFees403,
	PutFees500,
	PostFees400,
	PostFees403,
	PostFees500,
	GetFeesSort,
	useGetFeesFilterCountries,
	PutFees200DataItem,
	PostFees200DataItem,
	PutFees200,
	PostFees200,
	useGetFeesFilterCurrencies,
	useGetFeesFilterStatuses,
} from '@uturn/api/finance/v1';
import {
	AgGridReact,
	FillOperationParams,
	GridOptions,
	IServerSideDatasource,
	ITooltipParams,
	ProcessCellForExportParams,
	ProcessDataFromClipboardParams,
	sonner,
} from '@uturn/ui-kit';
import { FC, RefObject, useMemo, useRef } from 'react';
import { CellEditorFee, CellRendererFee } from './components/fee/cell';
import {
	isCellFee,
	castClipboard,
	findRowDataFee,
	getSubmittedFees,
	cellStyleFee,
	valueSetterFee,
	castPutFee,
	castPostFee,
	getSuccessMessage,
	getErrorMessage,
	setRowDataFee,
	getFeeDataColId,
	valueFormatterFee,
	getPrice,
} from './components/fee/utils';
import { feeTransportTypeLabels, feeTypeLabels } from './components/fee/labels';
import {
	FeeData,
	CellEditFee,
	RowData,
	RowDataFeesFlat,
	FeeClipboard,
} from './components/fee/types';
import { AxiosResponse, type AxiosError } from 'axios';
import { feeClipboardSchema } from './components/fee/schema';
import { useAbac } from 'react-abac';
import { Permissions } from '../../../../../abac';

const PAGE_SIZE = 50;

const Table: FC<{
	gridRef: RefObject<AgGridReact<RowData>>;
}> = ({ gridRef }) => {
	const { userHasPermissions } = useAbac();

	const { data: countries } = useGetFeesFilterCountries({
		query: { select: (data) => data.data },
	});

	const { data: currencies } = useGetFeesFilterCurrencies({
		query: { select: (data) => data.data },
	});

	const { data: statuses } = useGetFeesFilterStatuses({
		query: { select: (data) => data.data },
	});

	const { mutateAsync: mutateInsertAsync } = usePostFees();
	const { mutateAsync: mutateUpdateAsync } = usePutFees();

	const cellEditItemsRef = useRef<CellEditFee[]>([]);
	const cellEditCountRef = useRef(0);
	const cellEditResetRef = useRef(false);

	const serverSideDatasource: IServerSideDatasource = useMemo(
		() => ({
			getRows: async ({ api, request, success, fail }) => {
				try {
					const pageSize = api.paginationGetPageSize();
					const startRow = request.startRow ?? 0;

					const page = Math.ceil(startRow / pageSize);

					const { data: organizationsFees } = await getFees({
						page: page + 1,
						size: pageSize,
						orgNumber:
							request.filterModel && 'number' in request.filterModel
								? request.filterModel.number.filter
								: undefined,
						orgName:
							request.filterModel && 'name' in request.filterModel
								? request.filterModel.name.filter
								: undefined,
						orgCountry:
							request.filterModel && 'country.name' in request.filterModel
								? request.filterModel['country.name'].values
								: undefined,
						orgCurrency:
							request.filterModel && 'currency.code' in request.filterModel
								? request.filterModel['currency.code'].values
								: undefined,
						hasFees:
							import.meta.env.MODE === 'development'
								? 'True'
								: request.filterModel && 'hasFees' in request.filterModel
									? request.filterModel.hasFees.values
									: undefined,
						orgStatus:
							request.filterModel && 'status.code' in request.filterModel
								? request.filterModel['status.code'].values
								: undefined,
						orgId:
							request.filterModel && 'id' in request.filterModel
								? request.filterModel['id'].values
								: undefined,
						sort: request.sortModel[0]
							? (request.sortModel[0].colId as GetFeesSort)
							: undefined,
						direction: request.sortModel[0]
							? request.sortModel[0].sort
							: undefined,
					});

					if (organizationsFees.data.length === 0) {
						api.showNoRowsOverlay();
					} else {
						api.hideOverlay();
					}

					const rowData: RowData[] = organizationsFees.data.map((row) => ({
						...row,
						market_shunt: findRowDataFee(
							row,
							FeeTransport.SHUNT,
							FeeType.MARKET,
						),
						market_import: findRowDataFee(
							row,
							FeeTransport.IMPORT,
							FeeType.MARKET,
						),
						market_export: findRowDataFee(
							row,
							FeeTransport.EXPORT,
							FeeType.MARKET,
						),
						market_other: findRowDataFee(
							row,
							FeeTransport.OTHER,
							FeeType.MARKET,
						),
						contract_shunt: findRowDataFee(
							row,
							FeeTransport.SHUNT,
							FeeType.CONTRACT,
						),
						contract_import: findRowDataFee(
							row,
							FeeTransport.IMPORT,
							FeeType.CONTRACT,
						),
						contract_export: findRowDataFee(
							row,
							FeeTransport.EXPORT,
							FeeType.CONTRACT,
						),
						contract_other: findRowDataFee(
							row,
							FeeTransport.OTHER,
							FeeType.CONTRACT,
						),
					}));

					success({
						rowData,
						rowCount: organizationsFees.metaData.count,
					});
				} catch (err) {
					console.error(err);
					fail();
				}
			},
		}),
		[],
	);

	const sortableColumns = useMemo(() => Object.values(GetFeesSort), []);

	const onSuccessHandler = (
		response: AxiosResponse<PutFees200 | PostFees200>,
		cellEdits: CellEditFee[],
		inserting: boolean,
	) => {
		response.data?.data.forEach(
			(dbFeeData: PutFees200DataItem | PostFees200DataItem) => {
				const cellEdit = cellEdits.find(
					(value) =>
						value.rowNode.data?.id === dbFeeData.organizationId &&
						value.colId === getFeeDataColId(dbFeeData),
				);

				if (!cellEdit) {
					if (
						dbFeeData.transportType === FeeTransport.EXPORT ||
						dbFeeData.transportType === FeeTransport.OTHER
					) {
						// NOTE: *_export and *_other will not be found, but that is ok!
						// See getSubmittedFees for why they aren't in cellEdits.
						return;
					}
					console.error('onSuccessHandler.cellEdit.notFound', {
						cellEdits,
						dbFeeData,
					});
					return;
				}

				const finalValue = {
					...dbFeeData,
					index: cellEdit.currentCellValue.index,
					isDirty: false,
				};

				// Server side refresh with isDirty false
				setRowDataFee(cellEdit.rowNode, cellEdit.colId, finalValue);
			},
		);

		sonner.success(getSuccessMessage(inserting, cellEdits.length));
	};

	const onErrorHandler = (
		error: AxiosError<
			| PutFees400
			| PutFees403
			| PutFees500
			| PostFees400
			| PostFees403
			| PostFees500
		>,
		cellEdits: CellEditFee[],
		inserting: boolean,
	) => {
		// Server side rollback with isDirty false
		cellEdits.forEach((cellEdit) =>
			// TODO: Change function signature to setRowDataFee(cellEdit)?
			setRowDataFee(
				cellEdit.rowNode,
				cellEdit.colId,
				cellEdit.currentCellValue,
			),
		);

		sonner.error(getErrorMessage(error, inserting, cellEdits.length));
	};

	const updateServerSideFees = (cellEdits: CellEditFee[]) => {
		if (!cellEdits.length) {
			return;
		}

		mutateUpdateAsync(
			{
				data: cellEdits
					.map((cellEdit) =>
						// TODO: Change function signature to getSubmittedFees(cellEdit)?
						getSubmittedFees(
							cellEdit.rowNode.data!,
							cellEdit.currentCellValue,
							cellEdit.clipBoard,
						),
					)
					.flat()
					.map((fee) => castPutFee(fee)),
			},
			{
				onSuccess: (response) => onSuccessHandler(response, cellEdits, false),
				onError: (error) => onErrorHandler(error, cellEdits, false),
			},
		);
	};

	const insertServerSideFees = (cellEdits: CellEditFee[]) => {
		if (!cellEdits.length) {
			return;
		}

		mutateInsertAsync(
			{
				data: cellEdits
					.map((cellEdit) =>
						// TODO: Change function signature to getSubmittedFees(cellEdit)?
						getSubmittedFees(
							cellEdit.rowNode.data!,
							cellEdit.currentCellValue,
							cellEdit.clipBoard,
						),
					)
					.flat()
					.map((fee) => castPostFee(fee)),
			},
			{
				onSuccess: (response) => onSuccessHandler(response, cellEdits, true),
				onError: (error) => onErrorHandler(error, cellEdits, true),
			},
		);
	};

	const doCellEdit = () => {
		if (!cellEditItemsRef.current.length) {
			return;
		}

		updateServerSideFees(
			cellEditItemsRef.current.filter(
				(value) => value.currentCellValue.id !== -1,
			),
		);

		insertServerSideFees(
			cellEditItemsRef.current.filter(
				(value) => value.currentCellValue.id === -1,
			),
		);

		cellEditResetRef.current = false;
	};

	const undoCellEdit = () => {
		if (cellEditResetRef.current) {
			return;
		}

		if (!cellEditItemsRef.current.length) {
			sonner.error('Nothing to undo!');
			return;
		}

		updateServerSideFees(
			cellEditItemsRef.current
				.filter((value) => value.currentCellValue.id !== -1)
				.map((value) => ({
					...value,
					clipBoard: castClipboard(value.currentCellValue),
					currentCellValue: {
						...value.currentCellValue,
						...value.clipBoard,
					},
				})),
		);

		// NOTE: Undo insertServerSideFees means deleting the fee(s), so skipped.

		cellEditResetRef.current = true;
	};

	const redoCellEdit = () => {
		if (!cellEditResetRef.current) {
			return;
		}

		if (!cellEditItemsRef.current.length) {
			sonner.error('Nothing to redo!');
			return;
		}

		updateServerSideFees(
			cellEditItemsRef.current.filter(
				(value) => value.currentCellValue.id !== -1,
			),
		);

		// NOTE: Undo insertServerSideFees was skipped, so nothing to redo.

		cellEditResetRef.current = false;
	};

	// TODO: Block Paste and Fill Handle if any updated fees are missing.
	// TODO: Block Paste and Fill Handle if any source fees are missing.

	const gridOptions: GridOptions<RowData> = {
		// Column options
		enableBrowserTooltips: true,
		tooltipShowDelay: 0,
		tooltipTrigger: 'hover',
		tooltipMouseTrack: true,
		tooltipInteraction: true,
		suppressContextMenu: true,
		stopEditingWhenCellsLoseFocus: true,
		// suppressClipboardPaste: !userHasPermissions(Permissions.Fees.CopyPaste), // NOTE: columnTypes.fee.suppressPaste already takes care of that.
		columnTypes: {
			fee: {
				suppressFillHandle: !userHasPermissions(Permissions.Fees.FillHandle),
				suppressPaste: !userHasPermissions(Permissions.Fees.CopyPaste),
				floatingFilter: false,
				editable:
					userHasPermissions(Permissions.Fees.Create) ||
					userHasPermissions(Permissions.Fees.Update),
				sortable: true,
				cellStyle: cellStyleFee,
				valueSetter: valueSetterFee,
				cellRenderer: CellRendererFee,
				cellEditor: CellEditorFee,
				cellEditorPopup: true,
				cellEditorPopupPosition: 'under',
				lockVisible: true,
				headerTooltip: 'Sorting will sort flat and percentage fees separately.',
				tooltipValueGetter: (params: ITooltipParams<RowData, FeeData>) => {
					const { value, data } = params;

					if (!value || !data) {
						return '';
					}

					const { currency } = data;
					const tooltips = [`Fee: ${valueFormatterFee(value, currency)}.`];

					if (value.unit === 'PERCENTAGE') {
						if (value.feeMin > 0) {
							tooltips.push(
								`Cannot be lower than: ${getPrice(value.feeMin, currency)}.`,
							);
						}
						if (value.feeMax && value.feeMax > 0) {
							tooltips.push(
								`Cannot be higher than: ${getPrice(value.feeMax, currency)}.`,
							);
						}
					}

					tooltips.push(
						value.autoUpdate
							? 'Will be affected next time default fees change.'
							: 'Will "NOT" be affected next time default fees change.',
					);

					return tooltips.join('\n');
				},
			},
		},
		defaultColDef: {
			flex: 1,
			floatingFilter: false,
			editable: false,
			suppressFloatingFilterButton: true,
			suppressHeaderMenuButton: true,
			suppressHeaderContextMenu: true,
			suppressMovable: true,
			suppressFillHandle: true,
			enableCellChangeFlash: true,
		},
		columnDefs: [
			{
				headerName: 'Organization',
				children: [
					{
						headerName: 'ID',
						field: 'id',
						sortable: sortableColumns.includes('name'),
						filter: 'agNumberColumnFilter',
						hide: true,
						suppressFiltersToolPanel: true,
					},
					{
						headerName: 'Name',
						field: 'name',
						sortable: sortableColumns.includes('name'),
						filter: 'agTextColumnFilter',
						filterParams: {
							filterOptions: ['contains'],
						},
						lockVisible: true,
					},
					{
						headerName: 'Number',
						field: 'number',
						sortable: sortableColumns.includes('number'),
						filter: 'agNumberColumnFilter',
						filterParams: {
							filterOptions: ['equals'],
						},
						lockVisible: true,
					},
					{
						headerName: 'Country',
						field: 'country.name',
						sortable: sortableColumns.includes('country.name'),
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							values: countries?.data.map((country) => country.name),
						},
						hide: true,
					},
					{
						headerName: 'Currency',
						field: 'currency.code',
						sortable: sortableColumns.includes('currency.code'),
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							values: currencies?.data.map((currency) => currency.code),
						},
						hide: true,
					},
					{
						headerName: 'Status',
						field: 'status.code',
						sortable: sortableColumns.includes('status.code'),
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							values: statuses?.data.map((status) => status.code),
						},
						hide: true,
					},
					// {
					// 	headerName: 'Has fees',
					// 	colId: 'hasFees',
					// 	sortable: false,
					// 	filter: 'agSetColumnFilter',
					// 	filterParams: {
					// 		defaultToNothingSelected: true,
					// 		values: ['True'],
					// 	},
					// 	hide: true,
					// 	suppressColumnsToolPanel: true,
					// },
				],
			},
			{
				headerName: feeTypeLabels[FeeType.MARKET],
				children: [
					{
						headerName: feeTransportTypeLabels[FeeTransport.SHUNT],
						field: 'market_shunt',
						type: 'fee',
					},
					{
						headerName: feeTransportTypeLabels[FeeTransport.IMPORT],
						field: 'market_import',
						type: 'fee',
					},
				],
			},
			{
				headerName: feeTypeLabels[FeeType.CONTRACT],
				children: [
					{
						headerName: feeTransportTypeLabels[FeeTransport.SHUNT],
						field: 'contract_shunt',
						type: 'fee',
					},
					{
						headerName: feeTransportTypeLabels[FeeTransport.IMPORT],
						field: 'contract_import',
						type: 'fee',
					},
				],
			},
		],

		// Row options
		getRowId: (row) => row.data.id.toString(),

		// Server side options
		rowModelType: 'serverSide',
		serverSideDatasource,

		// Sidepanel options
		sideBar: {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true,
					},
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
					toolPanelParams: {
						suppressExpandAll: true,
						suppressFilterSearch: true,
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true,
					},
				},
			],
		},

		// Clipboard options
		suppressCutToClipboard: true,
		processCellForClipboard: (
			params: ProcessCellForExportParams<RowData, FeeData>,
		) => {
			const { value } = params;

			if (typeof value === 'object') {
				if (value.id === -1) {
					// NOTE: Skip non-existing fees.
					return undefined;
				}
				return JSON.stringify(value);
			}

			return value;
		},
		processDataFromClipboard: (
			params: ProcessDataFromClipboardParams<RowData>,
		) => {
			const { data } = params;

			cellEditCountRef.current = data.reduce((acc, val) => acc + val.length, 0);
			cellEditItemsRef.current = [];
			cellEditResetRef.current = false;

			return data;
		},
		processCellFromClipboard: (
			params: ProcessCellForExportParams<RowData, FeeData>,
		) => {
			const { column, node, value } = params;

			if (!value || !column || !node) {
				return null;
			}

			if (!isCellFee(column)) {
				sonner.error('Invalid column');
				return null;
			}

			const { data } = node;

			if (!data) {
				sonner.error('Data not found');
				return null;
			}

			const colId = column.getColId() as keyof RowDataFeesFlat;

			const currentCellValue: FeeData = data[colId];

			let parsedValue: Partial<FeeData> = {};
			try {
				parsedValue = JSON.parse(value);
			} catch (err) {
				console.error('processCellFromClipboard.value.json.parse', err);
				sonner.error('Invalid pasted json');
				return currentCellValue;
			}

			// if (parsedValue.id === -1) {
			// 	console.error('processCellFromClipboard.value.parsed.fee.missing', parsedValue);
			// 	sonner.error('Fee not found');
			// 	return currentCellValue;
			// }

			const validation = feeClipboardSchema.safeParse(parsedValue);
			if (!validation.success) {
				const error = validation.error;
				console.error('processCellFromClipboard.value.zod.parse', error);
				sonner.error('Invalid pasted fee');
				return currentCellValue;
			}

			const clipBoard: FeeClipboard = validation.data;

			const cellEditItem: CellEditFee = {
				// rowData: data,
				rowNode: node,
				colId,
				clipBoard,
				currentCellValue,
			};

			cellEditItemsRef.current.push(cellEditItem);

			if (
				cellEditCountRef.current > 0 &&
				cellEditCountRef.current === cellEditItemsRef.current.length
			) {
				doCellEdit();
			}

			const pendingCellValue: FeeData = {
				...currentCellValue,
				...clipBoard,
				isDirty: true,
			};

			// Optimistic update with isDirty true
			return pendingCellValue; // => valueSetter
		},

		// Fill Handle: https://www.ag-grid.com/react-data-grid/cell-selection-fill-handle/
		cellSelection: {
			suppressMultiRanges: true,
			handle: {
				mode: 'fill',
				direction: 'y',
				suppressClearOnFillReduction: true,
				setFillValue: (params: FillOperationParams<RowData>) => {
					const { column, rowNode, initialValues, currentCellValue } = params;

					if (initialValues.length !== 1) {
						sonner.error(
							initialValues.length > 1
								? 'Multi-cell fill not supported'
								: 'Source fill not found',
						);
						return currentCellValue; // => Skip
					}

					const clipBoard = castClipboard(initialValues[0]);

					cellEditItemsRef.current.push({
						rowNode,
						colId: column.getColId() as keyof RowDataFeesFlat,
						clipBoard,
						currentCellValue,
					});

					const pendingCellValue = {
						...currentCellValue,
						...clipBoard,
						isDirty: true,
					};

					// Optimistic update with isDirty true
					return pendingCellValue; // => valueSetter
				},
			},
		},
		onFillStart: () => {
			cellEditItemsRef.current = [];
			cellEditResetRef.current = false;
		},
		onFillEnd: doCellEdit,

		// Undo / Redo Edits: https://www.ag-grid.com/react-data-grid/undo-redo-edits/
		undoRedoCellEditing: userHasPermissions(Permissions.Fees.UndoRedo),
		undoRedoCellEditingLimit: 1, // restricts the number of undo / redo steps to 1
		onUndoStarted: () => {},
		onUndoEnded: undoCellEdit,
		onRedoStarted: () => {},
		onRedoEnded: redoCellEdit,

		// Pagination options
		pagination: true,
		paginationPageSize: PAGE_SIZE,
		cacheBlockSize: PAGE_SIZE,
		paginationPageSizeSelector: false,
	};

	return (
		<AgGridReact
			ref={gridRef}
			{...gridOptions}
			className="ag-theme-quartz align-baseline z-0"
		/>
	);
};

export default Table;
