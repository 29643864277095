import {
	SignedIn,
	SignedOut,
	useUser,
	useOrganization,
} from '@clerk/clerk-react';
import { Sonner, useTheme } from '@uturn/ui-kit';
import PublicRoutes from './routes/public';
import HttpClientProvider from './http/http-client-provider';
import FinanceApp from './modules/finance/app';
import OperationsApp from './modules/operations/app';
import SalesApp from './modules/sales/app';
import NoBackofficeTeam from './pages/no-backoffice-team';
import { useEffect } from 'react';
import { AbacProvider } from 'react-abac';
import { rules } from './abac';
import posthog from 'posthog-js';

const App = () => {
	const { isSignedIn, isLoaded, user } = useUser();
	const { theme } = useTheme();
	const { organization } = useOrganization();

	useEffect(() => {
		if (isLoaded && !isSignedIn && window.location.pathname !== '/') {
			window.location.href = '/?redirect=' + window.location.pathname;
		}
		if (isSignedIn) {
			posthog.identify(user.externalId ?? user.id, {
				email: user.emailAddresses[0].emailAddress,
			});
			user.organizationMemberships.forEach((membership) => {
				posthog.capture('user_signed_in_groups', {
					$groups: {
						team: membership.organization.slug,
					},
				});
				posthog.capture('$groupidentify', {
					$group_type: 'team',
					$group_key: membership.organization.slug,
					$group_set: {
						name: membership.organization.name,
					},
				});
			});
		}
	}, [isLoaded, isSignedIn]);

	if (!isLoaded) {
		return null;
	}

	return (
		<HttpClientProvider>
			<Sonner
				richColors
				closeButton
				theme={theme.mode}
				position="bottom-center"
			/>
			<SignedIn>
				<AbacProvider
					user={!!user}
					roles={user?.publicMetadata.roles as string[]}
					rules={rules}
				>
					{{
						finance: <FinanceApp />,
						operations: <OperationsApp />,
						sales: <SalesApp />,
						'no-organization': <NoBackofficeTeam />,
					}[organization?.slug ?? 'no-organization'] ?? <NoBackofficeTeam />}
				</AbacProvider>
			</SignedIn>
			<SignedOut>
				<PublicRoutes />
			</SignedOut>
		</HttpClientProvider>
	);
};

export default App;
