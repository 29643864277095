import { FC, PropsWithChildren, ReactNode } from 'react';
import {
	Button,
	cn,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	Icon,
	type ButtonProps,
} from '@uturn/ui-kit';
import {
	faChevronLeft,
	faEllipsisVertical,
} from '@fortawesome/pro-regular-svg-icons';
import { Link, LinkProps, useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs';

type ButtonAction = {
	label: ReactNode;
	className?: ButtonProps['className'];
	variant?: ButtonProps['variant'];
	disabled?: boolean;
	onClick: () => void;
};

type LinkAction = {
	label: ReactNode;
	href: string;
	className?: LinkProps['className'];
};

type Props = PropsWithChildren & {
	title?: string;
	className?: string;
	breadcrumbs?: boolean;
	backButton?: LinkAction;
	primaryActions?: ButtonAction[];
	secondaryActions?: ButtonAction[];
};

const Page: FC<Props> = ({
	title,
	className,
	children,
	breadcrumbs,
	backButton,
	primaryActions,
	secondaryActions,
}) => {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<div className={`flex flex-col h-full ${className}`}>
			{breadcrumbs && (
				<div className="flex items-center justify-between">
					<Breadcrumbs />
				</div>
			)}
			{backButton && (
				<div className="flex items-center justify-between">
					<nav aria-label="Back" className="print:hidden">
						<div className="mb-1 flex items-center space-x-1 text-sm text-muted-foreground">
							<Link
								className={cn(
									'flex items-center gap-1',
									backButton.className ?? '',
								)}
								to={location.state !== null ? '' : backButton.href}
								onClick={() => {
									if (location.state !== null) {
										navigate(-1);
									}
								}}
							>
								<Icon icon={faChevronLeft} />
								<span>{backButton.label}</span>
							</Link>
						</div>
					</nav>
				</div>
			)}
			<div className="flex items-center justify-between">
				<div>
					{title && (
						<h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl mb-6">
							{title}
						</h1>
					)}
				</div>
				<div className="flex items-center gap-2">
					{primaryActions &&
						primaryActions?.map((action, index) => (
							<Button
								key={`primary-action-${index}`}
								variant={action.variant ?? 'outline'}
								className={cn(
									'flex items-center gap-2',
									action.className ?? '',
								)}
								disabled={action.disabled}
								onClick={action.onClick}
							>
								{action.label}
							</Button>
						))}
					{secondaryActions && (
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button variant="outline" className="flex items-center gap-1">
									<Icon icon={faEllipsisVertical} />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								{secondaryActions?.map((action, index) => (
									<DropdownMenuItem
										key={`secondary-action-${index}`}
										className={cn(action.className ?? '')}
										disabled={action.disabled}
										onClick={action.onClick}
									>
										{action.label}
									</DropdownMenuItem>
								))}
							</DropdownMenuContent>
						</DropdownMenu>
					)}
				</div>
			</div>
			{children}
		</div>
	);
};

export default Page;
