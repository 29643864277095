import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		// See docs for support of different versions of variation of react router
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
	],
	environment: APP_ENV,
	enabled: !!APP_ENV && APP_ENV !== 'development',
	release: `backoffice-webapp-${import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA}`,

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: [
		'https://backoffice.uturn-system.com',
		'https://backoffice-staging.uturn-system.com',
	],
});
