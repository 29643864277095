import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '@uturn/ui-kit';
import {
	GetOrganizationsId200DataContactDetailsAddress,
	useGetOrganizationsIdBillingInfo,
} from '@uturn/api/backoffice/v1';
import { GenericUtils, CountryUtils } from '@uturn/utilities/browser';
import {
	DescriptiveList,
	DescriptiveListItem,
} from '../../../components/lists/descriptive.tsx';
import Invoices from '../../../components/organizations/invoices.tsx';
import InvoiceLines from '../../../components/organizations/invoice-lines/index.tsx';

type Props = {
	organizationId: number;
};

const BillingInfo = ({ organizationId }: Props) => {
	const { data: billingInfo } = useGetOrganizationsIdBillingInfo(
		organizationId,
		{
			query: {
				suspense: true,
				select: (data) => data.data.data,
			},
		},
	);

	const currencyNames = new Intl.DisplayNames(['en'], { type: 'currency' });

	const getCurrencySymbol = (code: string) => {
		const format = new Intl.NumberFormat('en-uk', {
			style: 'currency',
			currency: code.toUpperCase(),
		});
		return format.formatToParts(1).find((x) => x.type === 'currency')?.value;
	};

	const addressCopyFormat = (
		address?: GetOrganizationsId200DataContactDetailsAddress,
	) => {
		if (!address) return '';
		return `${address.line1}\n${address.line2 ? `${address.line2}\n` : ''}${address.postalCode}\n${address.city}${address.countryCode ? `\n${CountryUtils.countryCodeToName(address.countryCode)}`.trim() : ''}`;
	};

	return (
		<div className="grid grid-cols-2 gap-8">
			<Card>
				<CardHeader>
					<CardTitle>Billing information</CardTitle>
				</CardHeader>
				<CardContent>
					<DescriptiveList>
						<DescriptiveListItem
							label="Name"
							allowCopy={!!billingInfo?.invoiceDetails?.name}
						>
							{billingInfo?.invoiceDetails.name}
						</DescriptiveListItem>
						<DescriptiveListItem
							label="E-mail"
							copyValue={billingInfo?.invoiceDetails.email}
							allowCopy={!!billingInfo?.invoiceDetails?.email}
						>
							<a href={`mailto:${billingInfo?.invoiceDetails.email}`}>
								{billingInfo?.invoiceDetails.email}
							</a>
						</DescriptiveListItem>
						<DescriptiveListItem
							label="Address"
							allowCopy={!!billingInfo?.invoiceDetails?.address?.countryCode}
							copyValue={addressCopyFormat(billingInfo?.invoiceDetails.address)}
						>
							<address className="not-italic">
								<p>{billingInfo?.invoiceDetails.address?.line1}</p>
								<p>{billingInfo?.invoiceDetails.address?.line2}</p>
								<p>{billingInfo?.invoiceDetails.address?.postalCode}</p>
								<p>{billingInfo?.invoiceDetails.address?.city}</p>
								{billingInfo?.invoiceDetails.address?.countryCode && (
									<p>
										{CountryUtils.countryCodeToName(
											billingInfo?.invoiceDetails.address?.countryCode,
										)}
									</p>
								)}
							</address>
						</DescriptiveListItem>
						<DescriptiveListItem
							label="VAT number"
							allowCopy={!!billingInfo?.vatNumber}
						>
							{billingInfo?.vatNumber}
						</DescriptiveListItem>
						<DescriptiveListItem label="Currency used">
							{currencyNames.of(billingInfo?.currency ?? '')} (
							{getCurrencySymbol(billingInfo?.currency ?? '')})
						</DescriptiveListItem>
						<DescriptiveListItem
							label="Bank account number"
							allowCopy={!!billingInfo?.bankAccount?.number}
						>
							{billingInfo?.bankAccount.number}
						</DescriptiveListItem>
						<DescriptiveListItem
							label="BIC"
							allowCopy={!!billingInfo?.bankAccount?.bic}
						>
							{billingInfo?.bankAccount.bic}
						</DescriptiveListItem>
					</DescriptiveList>
				</CardContent>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>Payment details</CardTitle>
					<CardDescription>
						{GenericUtils.capitalizeFirstLetter(
							billingInfo?.organizationType ?? 'UNKNOWN',
						)}
					</CardDescription>
				</CardHeader>
				{billingInfo?.organizationType === 'SHIPPER' && (
					<CardContent>
						<DescriptiveList>
							<DescriptiveListItem label="Payment term">
								{billingInfo?.paymentTerms.term.description}
							</DescriptiveListItem>
							<DescriptiveListItem label="Cost fee">
								{billingInfo?.costFee.amount}
								{billingInfo?.costFee.unit}
							</DescriptiveListItem>
							<DescriptiveListItem label="Send invoices">
								{billingInfo?.invoiceDetails.sendInvoicesEnabled ? 'Yes' : 'No'}
							</DescriptiveListItem>
							<DescriptiveListItem label="Group invoices by reference">
								{billingInfo?.invoiceDetails.groupInvoicesByReference
									? 'Yes'
									: 'No'}
							</DescriptiveListItem>
							<DescriptiveListItem
								label="Backoffice approval required"
								helpText="Waiting for approval before sending invoices"
							>
								{billingInfo?.invoiceDetails.backofficeApprovalRequired
									? 'Yes'
									: 'No'}
							</DescriptiveListItem>
							<DescriptiveListItem label="Allow UBL export">
								{billingInfo?.invoiceDetails.allowUblExport ? 'Yes' : 'No'}
							</DescriptiveListItem>
							<DescriptiveListItem label="Attach Excel file to invoice">
								{billingInfo?.invoiceDetails.attachExcelFile ? 'Yes' : 'No'}
							</DescriptiveListItem>
						</DescriptiveList>
					</CardContent>
				)}
				{billingInfo?.organizationType === 'CARRIER' && (
					<CardContent>
						<DescriptiveList>
							<DescriptiveListItem label="Payment term">
								{billingInfo?.paymentTerms.term.description}
							</DescriptiveListItem>
							<DescriptiveListItem label="Fast payment fee">
								{billingInfo?.fastPaymentFee?.amount}
								{billingInfo?.fastPaymentFee?.unit}
							</DescriptiveListItem>
							<DescriptiveListItem label="Transport bonus">
								{billingInfo?.carrierTransportBonus.current}/
								{billingInfo?.carrierTransportBonus.total}
							</DescriptiveListItem>
							<DescriptiveListItem label="Invoice line generation disabled">
								{billingInfo?.invoiceDetails.backofficeApprovalRequired
									? 'Yes'
									: 'No'}
							</DescriptiveListItem>
						</DescriptiveList>
					</CardContent>
				)}
			</Card>
			{billingInfo?.organizationType === 'SHIPPER' && (
				<div className="col-span-2">
					<Invoices organizationId={organizationId} />
				</div>
			)}
			<div className="col-span-2">
				<InvoiceLines organizationId={organizationId} defaultPageSize={10} />
			</div>
		</div>
	);
};
export default BillingInfo;
