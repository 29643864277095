import {
	AgGridReact,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	DataTypeDefinition,
	GridOptions,
	IServerSideDatasource,
	MoneyCell,
	ValueFormatterLiteParams,
	ValueParserLiteParams,
} from '@uturn/ui-kit';
import {
	getOrganizationsIdInvoices,
	GetOrganizationsIdInvoices200DataItem,
} from '@uturn/api/finance/v1';
import { useMemo } from 'react';
import { DatesUtils } from '@uturn/utilities/browser';

type Props = {
	organizationId: number;
};

const PAGE_SIZE = 10;

const Table = ({ organizationId }: Props) => {
	const serverSideDatasource: IServerSideDatasource = useMemo(
		() => ({
			getRows: async ({ api, request, success, fail }) => {
				try {
					const pageSize = api.paginationGetPageSize();
					const startRow = request.startRow ?? 0;

					const page = Math.ceil(startRow / pageSize);

					const { data: invoices } = await getOrganizationsIdInvoices(
						organizationId,
						{
							page: page + 1,
							size: pageSize,
							sort: JSON.stringify(
								request.sortModel?.length > 0
									? request.sortModel.map(({ colId, sort }) => [colId, sort])
									: [['invoicedOn', 'DESC']],
							),
							id:
								request.filterModel && 'id' in request.filterModel
									? request.filterModel.id.filter
									: undefined,
							// @ts-ignore
							invoicedOn:
								request.filterModel && 'invoiceDate' in request.filterModel
									? request.filterModel.invoiceDate.dateFrom.split(' ')[0]
									: undefined,
						},
					);

					if (invoices.data.length === 0) {
						api.showNoRowsOverlay();
					} else {
						api.hideOverlay();
					}

					success({
						rowData: invoices.data,
						rowCount: invoices.metaData.count,
					});
				} catch (err) {
					console.error(err);
					fail();
				}
			},
		}),
		[],
	);

	const dataTypeDefinitions = useMemo<{
		[cellDataType: string]: DataTypeDefinition;
	}>(
		() => ({
			dateString: {
				baseDataType: 'dateString',
				extendsDataType: 'dateString',
				valueParser: (
					params: ValueParserLiteParams<
						GetOrganizationsIdInvoices200DataItem,
						string
					>,
				) => {
					if (!params.newValue) {
						return null;
					}
					return params.newValue;
				},
				valueFormatter: (
					params: ValueFormatterLiteParams<
						GetOrganizationsIdInvoices200DataItem,
						string
					>,
				) => {
					if (!params.value) return '';
					return DatesUtils.dateStringToFormattedDate(params.value, {
						dateFormat: 'dd/MM/yyyy',
					});
				},
				dateTypeMatcher: (value: any) => typeof value === 'string',
				dateParser: (value: string | undefined) => {
					if (!value) return undefined;
					return DatesUtils.dateStringToDate(value, { isISO: true });
				},
				dateFormatter: (value: Date | undefined) => {
					if (!value) return undefined;
					return DatesUtils.dateToFormattedString(value, {
						dateFormat: 'dd/MM/yyyy',
					});
				},
			},
		}),
		[],
	);

	const gridOptions: GridOptions<GetOrganizationsIdInvoices200DataItem> = {
		defaultColDef: {
			flex: 1,
			suppressFloatingFilterButton: true,
			suppressHeaderFilterButton: true,
			suppressHeaderMenuButton: true,
			suppressHeaderContextMenu: true,
			sortable: true,
		},
		columnDefs: [
			{
				field: 'id',
				headerName: 'Invoice number',
				filter: 'agNumberColumnFilter',
				cellDataType: 'number',
				flex: 0,
				floatingFilter: true,
				filterParams: {
					filterOptions: ['contains'],
				},
			},
			{
				field: 'amount',
				headerName: 'Cost amount',
				cellRenderer: MoneyCell,
				flex: 0,
				filter: 'agNumberColumnFilter',
				floatingFilter: false,
			},
			{
				field: 'vatAmount',
				headerName: 'VAT amount',
				cellRenderer: MoneyCell,
				filter: 'agNumberColumnFilter',
				floatingFilter: false,
			},
			{
				field: 'totalInvoice',
				headerName: 'Total invoiced',
				cellRenderer: MoneyCell,
				flex: 0,
				filter: 'agNumberColumnFilter',
				floatingFilter: false,
			},
			{
				field: 'invoiceDate',
				headerName: 'Invoice date',
				flex: 0,
				cellDataType: 'dateString',
				filter: 'agDateColumnFilter',
				floatingFilter: true,
			},
			{
				field: 'paymentPeriod',
				headerName: 'Payment period',
				flex: 0,
			},
		],
		rowModelType: 'serverSide',
		serverSideDatasource,
		pagination: true,
		paginationPageSize: PAGE_SIZE,
		cacheBlockSize: PAGE_SIZE,
		paginationPageSizeSelector: false,
	};
	return (
		<AgGridReact
			{...gridOptions}
			dataTypeDefinitions={dataTypeDefinitions}
			className="ag-theme-quartz align-baseline z-0 !h-96"
		/>
	);
};

const Invoices = ({ organizationId }: Props) => {
	return (
		<Card>
			<CardHeader>
				<CardTitle>Invoices</CardTitle>
			</CardHeader>
			<CardContent>
				<Table organizationId={organizationId} />
			</CardContent>
		</Card>
	);
};

export default Invoices;
