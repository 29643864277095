import Router from './routes/router';
import posthog from 'posthog-js';

export const App = () => {
	posthog.group('team', 'operations');
	posthog.capture('switched_teams');
	return <Router />;
};

export default App;
