import { createBrowserRouter, RouterProvider, UIMatch } from 'react-router-dom';
import Layout from '../../../layout/layout';
import Dashboard from './dashboard';
import {
	faChartSimple,
	faTruckTow,
	faBuildings,
	faPersonToPortal,
} from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from '@uturn/ui-kit';
import { useEffect } from 'react';
import OrganizationsOverview from './organization/overview.tsx';
import OrganizationDetails from './organization/detail.tsx';
import OrganizationErrorPage from './organization/error.tsx';
import OldBackoffice from './old-backoffice';

const navigation = [
	{ label: 'Dashboard', icon: faChartSimple, href: '/' },
	{ label: 'Organizations', icon: faBuildings, href: '/organizations' },
	{ label: 'Old Backoffice', icon: faPersonToPortal, href: '/old-backoffice' },
];

const router = createBrowserRouter([
	{
		element: (
			<Layout
				module={{ title: 'Operations', icon: faTruckTow }}
				navigation={navigation}
			/>
		),
		children: [
			{
				path: '/',
				element: <Dashboard />,
				handle: {
					crumb: () => 'Dashboard',
				},
			},
			{
				path: '/organizations',
				handle: {
					crumb: () => 'Organisations',
				},
				children: [
					{
						index: true,
						element: <OrganizationsOverview />,
					},
					{
						path: ':id',
						element: <OrganizationDetails />,
						errorElement: <OrganizationErrorPage />,
						handle: {
							crumb: (data: UIMatch) => data.params.id,
						},
					},
				],
			},
			{ path: '/old-backoffice', element: <OldBackoffice /> },
		],
	},
]);

const Router = () => {
	const { setColor } = useTheme();
	useEffect(() => {
		setColor('operations');
	}, []);
	return <RouterProvider router={router} />;
};

export default Router;
