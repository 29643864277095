import { UIMatch, useMatches } from 'react-router-dom';

const useBreadcrumbs = () => {
	const matches = useMatches() as UIMatch<
		unknown,
		{ crumb: (match: unknown) => string } | undefined
	>[];
	const crumbs = matches
		.filter((match) => Boolean(match.handle?.crumb))
		.map((match) => {
			return {
				name: match.handle?.crumb(match),
				path: match.pathname,
			};
		});

	return crumbs;
};

export default useBreadcrumbs;
