import { PropsWithChildren } from 'react';
import { CopyToClipboard } from '@uturn/ui-kit';

type Props = PropsWithChildren & {};

export const DescriptiveList = ({ children }: Props) => {
	return <dl className="divide-y divide-gray-100">{children}</dl>;
};

type ListItemProps = PropsWithChildren & {
	label: string;
	helpText?: string;
	allowCopy?: boolean;
	copyValue?: string;
};

export const DescriptiveListItem = ({
	label,
	copyValue,
	helpText,
	allowCopy,
	children,
}: ListItemProps) => {
	return (
		<div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
			<dt className="text-sm/6 font-medium text-gray-900">
				<p>{label}</p>
				{!!helpText && (
					<p className="text-xs text-gray-500 font-normal">{helpText}</p>
				)}
			</dt>
			<dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
				<span className="flex items-start">
					{children}
					{allowCopy && (
						<CopyToClipboard
							value={copyValue ?? children?.toString() ?? ''}
							successMessage="Copied"
							className="ml-2 h-auto w-auto"
						/>
					)}
				</span>
			</dd>
		</div>
	);
};
