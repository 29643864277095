import {
	AgGridReact,
	GridOptions,
	IServerSideDatasource,
	MoneyCell,
} from '@uturn/ui-kit';
import {
	getOrganizationsIdInvoicesInvoiceLines,
	GetOrganizationsIdInvoicesInvoiceLines200DataItem,
	GetOrganizationsIdInvoicesInvoiceLinesSort,
	useGetInvoicesInvoiceLinesStatuses,
	useGetLedgers,
} from '@uturn/api/finance/v1';
import { FC, RefObject, useMemo } from 'react';
import { formatDate } from '../../../util/format-date';

type ValuesParams = {
	success: (value: string[]) => void;
};

const PAGE_SIZE = 50;

const dateValueFormatter = (timestamp: number | undefined) =>
	timestamp ? formatDate(timestamp) : '';

const Table: FC<{
	id: number;
	defaultPageSize?: number;
	gridRef: RefObject<
		AgGridReact<GetOrganizationsIdInvoicesInvoiceLines200DataItem>
	>;
}> = ({ id, gridRef, defaultPageSize }) => {
	const { data: invoiceLineStatuses } = useGetInvoicesInvoiceLinesStatuses(
		{},
		{
			query: {
				select: (data) => data.data,
			},
		},
	);
	const { data: ledgers } = useGetLedgers(
		{},
		{ query: { select: (data) => data.data } },
	);
	const serverSideDatasource: IServerSideDatasource = useMemo(
		() => ({
			getRows: async ({ api, request, success, fail }) => {
				try {
					const pageSize = api.paginationGetPageSize();
					const startRow = request.startRow ?? 0;

					const page = Math.ceil(startRow / pageSize);

					const { data: invoiceLines } =
						await getOrganizationsIdInvoicesInvoiceLines(Number(id), {
							page: page + 1,
							size: pageSize,
							ledger:
								request.filterModel && 'ledger' in request.filterModel
									? request.filterModel.ledger.values
									: undefined,
							reference:
								request.filterModel && 'reference' in request.filterModel
									? request.filterModel.reference.filter
									: undefined,
							status:
								request.filterModel && 'status' in request.filterModel
									? request.filterModel.status.values
									: undefined,
							sort: request.sortModel[0]
								? (request.sortModel[0]
										.colId as GetOrganizationsIdInvoicesInvoiceLinesSort)
								: undefined,
							direction: request.sortModel[0]
								? request.sortModel[0].sort
								: undefined,
						});

					if (invoiceLines.data.length === 0) {
						api.showNoRowsOverlay();
					} else {
						api.hideOverlay();
					}

					success({
						rowData: invoiceLines.data,
						rowCount: invoiceLines.metaData.count,
					});
				} catch (err) {
					console.error(err);
					fail();
				}
			},
		}),
		[id],
	);

	const sortableColumns = useMemo(
		() => Object.values(GetOrganizationsIdInvoicesInvoiceLinesSort),
		[],
	);
	const gridOptions: GridOptions<GetOrganizationsIdInvoicesInvoiceLines200DataItem> =
		{
			defaultColDef: {
				suppressFloatingFilterButton: true,
				suppressHeaderFilterButton: true,
				suppressHeaderMenuButton: true,
				suppressHeaderContextMenu: true,
				sortable: false,
			},
			columnDefs: [
				{
					field: 'description',
					headerName: 'Description',
					sortable: sortableColumns.includes('description'),
				},
				{
					field: 'reference',
					headerName: 'Reference',
					filter: 'agTextColumnFilter',
					floatingFilter: true,
					filterParams: {
						filterOptions: ['contains'],
					},
					sortable: sortableColumns.includes('reference'),
				},
				{
					field: 'status',
					headerName: 'Status',
					filter: 'agSetColumnFilter',
					filterParams: {
						defaultToNothingSelected: true,
						values: (params: ValuesParams) =>
							params.success(
								invoiceLineStatuses?.data
									.filter((status) => status.description)
									// We assert the type to string because we have checked that the status has a description in the filter above
									.map((status) => status.description as string) ?? [],
							),
					},
					floatingFilter: true,
					suppressHeaderFilterButton: false,
					sort: 'desc',
					sortable: sortableColumns.includes('status'),
				},
				{
					field: 'ledger',
					headerName: 'Ledger',
					filter: 'agSetColumnFilter',
					filterParams: {
						defaultToNothingSelected: true,
						values: (params: ValuesParams) =>
							params.success(
								ledgers?.data.map((status) => status.description) ?? [],
							),
					},
					floatingFilter: true,
					suppressHeaderFilterButton: false,
					sortable: sortableColumns.includes('ledger'),
				},
				{
					field: 'amount',
					headerName: 'Amount',
					cellRenderer: MoneyCell,
					sortable: sortableColumns.includes('amount'),
				},
				{
					field: 'vatPercentage',
					headerName: 'VAT %',
					valueFormatter: (params) => `${params.data?.vatPercentage}%`,
					sortable: sortableColumns.includes('vatPercentage'),
				},
				{
					field: 'vat',
					headerName: 'VAT',
					cellRenderer: MoneyCell,
					sortable: sortableColumns.includes('vat'),
				},
				{
					field: 'totalAmount',
					headerName: 'Total amount',
					cellRenderer: MoneyCell,
					sortable: sortableColumns.includes('totalAmount'),
				},
				{
					field: 'approvedAt',
					headerName: 'Approved on',
					valueFormatter: (params) =>
						dateValueFormatter(params.data?.approvedAt),
					sortable: sortableColumns.includes('approvedAt'),
				},
				{
					field: 'invoiceNumber',
					headerName: 'Invoice number',
					sortable: sortableColumns.includes('invoiceNumber'),
				},
			],
			rowModelType: 'serverSide',
			serverSideDatasource,
			pagination: true,
			paginationPageSize: defaultPageSize ?? PAGE_SIZE,
			cacheBlockSize: defaultPageSize ?? PAGE_SIZE,
			paginationPageSizeSelector: false,
			rowSelection: 'multiple',
			suppressRowClickSelection: true,
			getRowId: (row) => row.data.id.toString(),
		};

	return (
		<AgGridReact
			ref={gridRef}
			className="ag-theme-quartz align-baseline h-full"
			{...gridOptions}
		/>
	);
};

export default Table;
