import {
	Breadcrumb,
	BreadcrumbList,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbSeparator,
	BreadcrumbPage,
} from '@uturn/ui-kit';
import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from '../../hooks/use-breadcrumbs';

const Breadcrumbs: React.FC = () => {
	const crumbs = useBreadcrumbs();

	return (
		<Breadcrumb>
			<BreadcrumbList>
				{crumbs.map((crumb, index) => (
					<BreadcrumbItem key={crumb.name}>
						{index === crumbs.length - 1 ? (
							<BreadcrumbPage>{crumb.name}</BreadcrumbPage>
						) : (
							<>
								<BreadcrumbLink asChild>
									<Link to={crumb.path}>{crumb.name}</Link>
								</BreadcrumbLink>
								<BreadcrumbSeparator />
							</>
						)}
					</BreadcrumbItem>
				))}
			</BreadcrumbList>
		</Breadcrumb>
	);
};

export default Breadcrumbs;
