import GeneralInfo from '../../../components/organizations/general-info.tsx';
import ContactDetails from '../../../components/organizations/contact-details.tsx';
import CarrierDetails from '../../../components/organizations/carrier-details.tsx';

type Props = {
	organizationId?: number;
	organizationType: 'SHIPPER' | 'CARRIER' | 'BACKOFFICE' | 'UNKNOWN' | string;
};

const Details = ({ organizationId, organizationType }: Props) => {
	if (!organizationId) {
		return <div>Organisation ID not found</div>;
	}
	return (
		<div className="grid grid-cols-2 gap-6">
			<GeneralInfo organizationId={organizationId} />
			<ContactDetails organizationId={organizationId} />
			{organizationType === 'CARRIER' && (
				<CarrierDetails organizationId={organizationId} />
			)}
		</div>
	);
};

export default Details;
