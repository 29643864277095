import { useNavigate, useParams } from 'react-router-dom';
import OrganizationDetail from '../../../../pages/organization/detail';
import { useEffect } from 'react';

const Detail = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!id || (id && isNaN(parseInt(id)))) {
			navigate('/organizations');
		}
	}, [id, navigate]);

	if (!id || (id && isNaN(parseInt(id)))) {
		return null;
	}

	return <OrganizationDetail id={parseInt(id)} />;
};

export default Detail;
