const Index = () => {
	return (
		<iframe
			src={import.meta.env.VITE_OLD_BACKOFFICE_URL}
			className="border-0 w-full h-full -m-6"
		></iframe>
	);
};

export default Index;
