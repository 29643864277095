import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '@uturn/ui-kit';
import { useGetOrganizationsIdProducts } from '@uturn/api/backoffice/v1';
import { DatesUtils, CurrencyUtils } from '@uturn/utilities/browser';

type Props = {
	organizationId: number;
};

const Products = ({ organizationId }: Props) => {
	const { data: products } = useGetOrganizationsIdProducts(organizationId, {
		query: {
			suspense: true,
			select: (data) => data.data.data,
		},
	});

	return (
		<>
			{products?.priceCalculatorSubscription && (
				<Card>
					<CardHeader>
						<CardTitle>Price Calculator</CardTitle>
					</CardHeader>
					<CardContent>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead>Tier</TableHead>
									<TableHead>Price</TableHead>
									<TableHead>Start date</TableHead>
									<TableHead>End date</TableHead>
									<TableHead>Auto renew</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								<TableRow>
									<TableCell>
										{products.priceCalculatorSubscription.tier}
									</TableCell>
									<TableCell>
										{CurrencyUtils.formatPriceString(
											products.priceCalculatorSubscription.price.amount ?? 0,
											products.priceCalculatorSubscription.price.currency ??
												'EUR',
											'nl-NL',
										)}
									</TableCell>
									<TableCell>
										{DatesUtils.dateStringToFormattedDate(
											products.priceCalculatorSubscription.startDate ?? '',
											{ dateFormat: 'dd/MM/yyyy' },
										)}
									</TableCell>
									<TableCell>
										{products.priceCalculatorSubscription.endDate
											? DatesUtils.dateStringToFormattedDate(
													products.priceCalculatorSubscription.endDate,
													{ dateFormat: 'dd/MM/yyyy' },
												)
											: ''}
									</TableCell>
									<TableCell>
										{products.priceCalculatorSubscription.autoRenew
											? 'Yes'
											: 'No'}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			)}
			{products?.stripeSubscriptions && (
				<Card>
					<CardHeader>
						<CardTitle>Subscriptions</CardTitle>
					</CardHeader>
					<CardContent>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead>Name</TableHead>
									<TableHead>Status</TableHead>
									<TableHead>Price</TableHead>
									<TableHead>Started on</TableHead>
									<TableHead>Cancellation</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{products.stripeSubscriptions.map((subscription) => (
									<TableRow key={subscription.id}>
										<TableCell>{subscription.productName}</TableCell>
										<TableCell>{subscription.status}</TableCell>
										<TableCell>
											{CurrencyUtils.formatPriceString(
												subscription.price.amount / 100,
												subscription.price.currency,
												'nl',
											)}
											/{subscription.period.interval}
										</TableCell>
										<TableCell>
											{DatesUtils.dateStringToFormattedDate(
												subscription.startDate,
											)}
										</TableCell>
										<TableCell>
											{subscription.cancellation?.cancelOn
												? DatesUtils.dateStringToFormattedDate(
														subscription.cancellation?.cancelOn,
													)
												: ''}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			)}
		</>
	);
};
export default Products;
