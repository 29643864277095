import { Helmet } from 'react-helmet-async';
import Table from './table';
import Page from '../../../layout/page.tsx';

const Overview = () => {
	return (
		<>
			<Helmet title="Organizations" />
			<Page title="Organizations">
				<div className="flex-1">
					<Table />
				</div>
			</Page>
		</>
	);
};

export default Overview;
