import { useGetOrganizationsIdCarrierDetails } from '@uturn/api/backoffice/v1';
import { Card, CardContent, CardHeader, CardTitle } from '@uturn/ui-kit';
import { DescriptiveList, DescriptiveListItem } from '../lists/descriptive.tsx';
import { CountryUtils } from '@uturn/utilities/browser';

type Props = {
	organizationId: number;
};

const CarrierDetails = ({ organizationId }: Props) => {
	const { data: carrierDetails } = useGetOrganizationsIdCarrierDetails(
		organizationId,
		{
			query: {
				suspense: true,
				select: (data) => data.data.data,
			},
		},
	);

	return (
		<Card>
			<CardHeader>
				<CardTitle>Carrier details</CardTitle>
			</CardHeader>
			<CardContent>
				<DescriptiveList>
					<DescriptiveListItem label="EAN" allowCopy={!!carrierDetails?.ean}>
						{carrierDetails?.ean}
					</DescriptiveListItem>
					<DescriptiveListItem label="Number of trucks">
						{carrierDetails?.numberOfTrucks}
					</DescriptiveListItem>
					<DescriptiveListItem label="Transport types">
						<ul>
							{carrierDetails?.containerSizes.map((size) => (
								<li key={size.code}>{size.description}</li>
							))}
						</ul>
					</DescriptiveListItem>
					<DescriptiveListItem label="Capabilities">
						<ul>
							{carrierDetails?.capabilities.map((capability) => (
								<li key={capability}>{capability}</li>
							))}
						</ul>
					</DescriptiveListItem>
					<DescriptiveListItem label="Active in countries">
						<ul>
							{carrierDetails?.activeInCountries.map((country) => (
								<li key={country}>
									{CountryUtils.countryCodeToName(country, 'en')}
								</li>
							))}
						</ul>
					</DescriptiveListItem>
					{carrierDetails?.review && (
						<DescriptiveListItem label="Review">
							{carrierDetails?.review?.averageRating} (out of{' '}
							{carrierDetails?.review?.count} reviews)
						</DescriptiveListItem>
					)}
				</DescriptiveList>
			</CardContent>
		</Card>
	);
};

export default CarrierDetails;
