import { Helmet } from 'react-helmet-async';
import Table from './table';
import Page from '../../../../../layout/page';
import { AgGridReact, Icon } from '@uturn/ui-kit';
import { useRef, useState } from 'react';
import Overlay from '../../../../../components/overlay/overlay';
import { RowData } from './components/fee/types';
import { faGear } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { AllowedTo } from 'react-abac';
import { Permissions } from '../../../../../abac';
import ErrorPage from './components/error/error';

const Overview = () => {
	const navigate = useNavigate();
	const ref = useRef<AgGridReact<RowData>>(null);

	const [isLoading] = useState(false);

	return (
		<>
			<Helmet title="Fees" />
			{isLoading && <Overlay>Loading...</Overlay>}
			<Page
				title="Fees"
				primaryActions={[
					{
						label: (
							<>
								<Icon icon={faGear} />
								Change defaults
							</>
						),
						onClick: () => navigate('/fees/defaults'),
					},
				]}
			>
				<AllowedTo
					perform={Permissions.Fees.Read}
					no={() => (
						<ErrorPage>
							Access denied: You do not have access to view this content.
						</ErrorPage>
					)}
				>
					<div className="flex-1">
						<Table gridRef={ref} />
					</div>
				</AllowedTo>
			</Page>
		</>
	);
};

export default Overview;
