import Router from './routes/router';
import { useTheme } from '@uturn/ui-kit';
import { useEffect } from 'react';
import HttpClientProvider from './http/http-client-provider.tsx';
import posthog from 'posthog-js';

const FinanceApp = () => {
	const { setColor } = useTheme();
	useEffect(() => {
		setColor('finance');
	}, []);
	posthog.group('team', 'finance');
	posthog.capture('switched_teams');

	return (
		<HttpClientProvider>
			<Router />
		</HttpClientProvider>
	);
};

export default FinanceApp;
