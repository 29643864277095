import {
	GetOrganizationsId200DataContactDetailsAddress,
	useGetOrganizationsIdContactDetails,
} from '@uturn/api/backoffice/v1';
import { Card, CardContent, CardHeader, CardTitle } from '@uturn/ui-kit';
import { DescriptiveList, DescriptiveListItem } from '../lists/descriptive.tsx';
import { CountryUtils } from '@uturn/utilities/browser';

type Props = {
	organizationId: number;
};

const ContactDetails = ({ organizationId }: Props) => {
	const { data: contactDetails } = useGetOrganizationsIdContactDetails(
		organizationId,
		{
			query: {
				suspense: true,
				select: (data) => data.data.data,
			},
		},
	);

	const addressCopyFormat = (
		address?: GetOrganizationsId200DataContactDetailsAddress,
	) => {
		if (!address) return '';
		return `${address.line1}\n${address.line2 ? `${address.line2}\n` : ''}${address.postalCode}\n${address.city}${address.countryCode ? `\n${CountryUtils.countryCodeToName(address.countryCode)}` : ''}`;
	};

	return (
		<Card>
			<CardHeader>
				<CardTitle>Contact details</CardTitle>
			</CardHeader>
			<CardContent>
				<DescriptiveList>
					<DescriptiveListItem
						label="Email"
						copyValue={contactDetails?.email}
						allowCopy={!!contactDetails?.email}
					>
						<a href={`mailto:${contactDetails?.email}`}>
							{contactDetails?.email}
						</a>
					</DescriptiveListItem>
					<DescriptiveListItem
						label="Phone"
						allowCopy={!!contactDetails?.phone}
					>
						{contactDetails?.phone}
					</DescriptiveListItem>
					<DescriptiveListItem
						label="Out of office phone number"
						allowCopy={!!contactDetails?.outOfOfficePhone}
					>
						{contactDetails?.outOfOfficePhone}
					</DescriptiveListItem>
					<DescriptiveListItem
						label="Address"
						allowCopy={!!contactDetails?.address?.countryCode}
						copyValue={addressCopyFormat(contactDetails?.address)}
					>
						<address className="not-italic">
							<p>{contactDetails?.address?.line1}</p>
							<p>{contactDetails?.address?.line2}</p>
							<p>{contactDetails?.address?.postalCode}</p>
							<p>{contactDetails?.address?.city}</p>
							{contactDetails?.address?.countryCode && (
								<p>
									{CountryUtils.countryCodeToName(
										contactDetails?.address?.countryCode,
									)}
								</p>
							)}
						</address>
					</DescriptiveListItem>
					<DescriptiveListItem label="Website">
						<a href={contactDetails?.website} target="_blank">
							{contactDetails?.website}
						</a>
					</DescriptiveListItem>
				</DescriptiveList>
			</CardContent>
		</Card>
	);
};

export default ContactDetails;
