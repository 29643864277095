import { Outlet } from 'react-router-dom';
import Header from './header/header';
import { Suspense } from 'react';
import Loader from '../components/loader/loader';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../pages/error';
import Sidebar from './sidebar';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

type Props = {
	navigation?: { label: string; icon: IconDefinition; href: string }[];
	module: {
		title: string;
		icon: IconDefinition;
	};
};

const Layout = ({ navigation, module }: Props) => (
	<div className="grid grid-cols-[min-content_auto] h-full overflow-hidden">
		<Sidebar module={module} items={navigation ?? []} />

		<div className="flex-1 grid grid-rows-[min-content_auto] h-screen min-h-screen overflow-auto">
			<Header />
			<main className="h-full p-6">
				<ErrorBoundary fallbackRender={ErrorPage}>
					<Suspense fallback={<Loader />}>
						<Outlet />
					</Suspense>
				</ErrorBoundary>
			</main>
		</div>
	</div>
);

export default Layout;
