import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@uturn/ui-kit';
import { PropsWithChildren } from 'react';

const ErrorPage: React.FC<PropsWithChildren> = ({ children }) => (
	<div className="h-full flex justify-center items-center">
		<div className="flex flex-col gap-2 text-red-600">
			<Icon size="2xl" icon={faCircleExclamation} />
			{children}
		</div>
	</div>
);

export default ErrorPage;
