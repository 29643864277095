import {
	GetFees200DataItem,
	GetFees200DataItemFeesItem,
	GetFees200DataItemFeesItemTransportType as FeeTransport,
	GetFees200DataItemFeesItemType as FeeType,
	GetFees200DataItemFeesItemUnit as FeeUnit,
	PutFeesBodyItem,
	PostFeesBodyItem,
	PostFees400,
	PostFees403,
	PostFees500,
	PutFees400,
	PutFees403,
	PutFees500,
} from '@uturn/api/finance/v1';
import {
	CellClassParams,
	CellStyle,
	Column,
	IRowNode,
	ValueSetterParams,
} from '@uturn/ui-kit';
import { FeeData, FeeClipboard, RowData, RowDataFeesFlat } from './types';
import { feeTransportTypeLabels } from './labels';
import { type AxiosError } from 'axios';

const onlyUnique = <T>(value: T, index: number, array: T[]) => {
	return array.indexOf(value) === index;
};

export const isCellFee = (column: Column) => {
	// TODO: Improve this.
	return Object.values(feeTransportTypeLabels)
		.filter(onlyUnique)
		.includes(column.getColDef().headerName ?? '');
};

export const getNewFee = (
	organizationId: number,
	transportType: FeeTransport,
	type: FeeType,
): FeeData => {
	return {
		id: -1,
		organizationId,
		fee: 0,
		unit: FeeUnit.FLAT,
		transportType,
		type,
		feeMin: 0,
		feeMax: undefined,
		autoUpdate: false,
		createdAt: new Date().toISOString(),
		index: -1,
		isDirty: false,
	};
};

export const findRowDataFee = (
	data: GetFees200DataItem,
	transportType: FeeTransport,
	type: FeeType,
) => {
	const fees: GetFees200DataItemFeesItem[] = data.fees;

	if (!fees.length) {
		return getNewFee(data.id, transportType, type);
	}

	const index = fees.findIndex(
		(fee) => fee.transportType === transportType && fee.type === type,
	);

	if (index === -1) {
		return getNewFee(data.id, transportType, type);
	}

	const value: FeeData = {
		...fees[index],
		index,
		isDirty: false,
	};

	return value;
};

export const getFeeDataColId = (data: GetFees200DataItemFeesItem) => {
	return getFeeColId(data.type, data.transportType);
};

/**
[getFeeColId(FeeType.MARKET,   FeeTransport.SHUNT  )]: market_shunt
[getFeeColId(FeeType.MARKET,   FeeTransport.IMPORT )]: market_import
[getFeeColId(FeeType.MARKET,   FeeTransport.EXPORT )]: market_export
[getFeeColId(FeeType.MARKET,   FeeTransport.OTHER  )]: market_other
[getFeeColId(FeeType.CONTRACT, FeeTransport.SHUNT  )]: contract_shunt
[getFeeColId(FeeType.CONTRACT, FeeTransport.IMPORT )]: contract_import
[getFeeColId(FeeType.CONTRACT, FeeTransport.EXPORT )]: contract_export
[getFeeColId(FeeType.CONTRACT, FeeTransport.OTHER  )]: contract_other */
export const getFeeColId = (type: FeeType, transportType: FeeTransport) => {
	return `${type.toLowerCase()}_${transportType.toLowerCase()}` as keyof RowDataFeesFlat;
};

export const cellStyleFee = (params: CellClassParams<RowData, FeeData>) => {
	const {
		// column,
		// colDef,
		// data,
		// node,
		// rowIndex,
		// api,
		// context,
		value,
	} = params;

	const defaultStyle: CellStyle = {
		color: 'black',
		backgroundColor: 'white',
	};

	if (!value) {
		return defaultStyle;
	}

	if (value.error) {
		return {
			color: 'black',
			backgroundColor: '#f18888',
		};
	}

	if (value.isDirty) {
		return {
			color: 'grey',
			backgroundColor: 'white',
		};
	}

	return defaultStyle;
};

export const setRowDataFee = (
	rowNode: IRowNode<RowData> | null,
	colId: keyof RowDataFeesFlat,
	feeData: FeeData,
	option?: { data?: RowData },
) => {
	if (!rowNode) {
		return;
	}

	const rowData = option?.data ?? rowNode.data;

	if (!rowData) {
		return;
	}

	if (!feeData) {
		return;
	}

	const { index } = feeData;
	const newFees = [...rowData.fees];
	// TODO: Fix CellEditorFee too?
	if (index === -1) {
		feeData.index = newFees.length;
		newFees.push(feeData);
	} else {
		newFees[index] = feeData;
	}
	const newRow: RowData = { ...rowData, fees: newFees, [colId]: feeData };
	rowNode.setData(newRow);
};

export const valueSetterFee = (params: ValueSetterParams<RowData, FeeData>) => {
	const { column, data, newValue, node } = params;

	if (!newValue) {
		return false;
	}

	const colId = column.getColId() as keyof RowDataFeesFlat;

	// Client side refresh
	// TODO: Change function signature to setRowDataFee to use feeCell?
	setRowDataFee(node, colId, newValue, { data });

	return true;
};

export const getFeeUnit = (unit: FeeUnit, currency: RowData['currency']) => {
	switch (unit) {
		case FeeUnit.PERCENTAGE:
			return '%';
		case FeeUnit.FLAT:
		default:
			return currency.sign ?? '€';
	}
};

export const getPrice = (amount: number, currency?: RowData['currency']) => {
	const currencySign = currency?.sign ?? '€';
	if (!amount) {
		return '-'; // `${currencySign}-`;
	}
	return `${currencySign}${amount.toFixed(2)}`;
};

export const valueFormatterFee = (
	fee: FeeData,
	currency: RowData['currency'],
) => {
	const amount = fee.fee ?? 0;

	if (fee.unit === FeeUnit.PERCENTAGE) {
		return `${amount}%`;
	}

	return getPrice(amount, currency);
};

export const getSuccessMessage = (inserting: boolean, count: number = 1) => {
	const resource = count > 1 ? `${count} fees have` : 'Fee has';

	return `${resource} been ${inserting ? 'created' : 'updated'}`;
};

export const getErrorMessage = (
	error: AxiosError<
		| PutFees400
		| PutFees403
		| PutFees500
		| PostFees400
		| PostFees403
		| PostFees500
	>,
	inserting: boolean,
	count: number = 1,
) => {
	const resource = count > 1 ? 'fees' : 'fee';

	if (error.response?.data.code === 'EMPTY_BODY') {
		return 'Nothing to update, please review your change';
	}

	if (error.response?.data.code === 'RESOURCE_NOT_FOUND') {
		return 'Fee not found, please review your change';
	}

	return `A unknown error occured while trying to ${inserting ? 'create' : 'update'} the ${resource}`;
};

/**
 * Prepare fee data to submit for server side update.
 *
 * For now, `Drayage` implies `Import`, `Export` and `Other`.
 * Therefore, if a `Drayage` fee needs updating, then all 3 underlying fees should be updated. */
export const getSubmittedFees = (
	data: RowData,
	oldValue: FeeData,
	clipBoard: FeeClipboard,
) => {
	const result: GetFees200DataItemFeesItem[] = [
		{
			...oldValue,
			...clipBoard,
		},
	];

	if (oldValue.transportType === FeeTransport.SHUNT) {
		return result;
	}

	if (oldValue.type === FeeType.CONTRACT) {
		result.push({
			...data.contract_export,
			...clipBoard,
		});

		result.push({
			...data.contract_other,
			...clipBoard,
		});

		return result;
	}

	result.push({
		...data.market_export,
		...clipBoard,
	});

	result.push({
		...data.market_other,
		...clipBoard,
	});

	return result;
};

export const castClipboard = (value: FeeData): FeeClipboard => {
	const { fee, unit, autoUpdate, feeMin, feeMax } = value;
	return {
		fee,
		unit,
		autoUpdate,
		feeMin,
		feeMax,
	};
};

export const castPostFee = (
	value: GetFees200DataItemFeesItem,
): PostFeesBodyItem => {
	const {
		organizationId,
		transportType,
		type,
		fee,
		unit,
		feeMax,
		feeMin,
		autoUpdate,
	} = value;
	return {
		organizationId,
		transportType,
		type,
		fee,
		unit,
		feeMax,
		feeMin,
		autoUpdate,
	};
};

export const castPutFee = (
	value: GetFees200DataItemFeesItem,
): PutFeesBodyItem => {
	const {
		id,
		organizationId,
		transportType,
		type,
		fee,
		unit,
		feeMax,
		feeMin,
		autoUpdate,
	} = value;
	return {
		id,
		organizationId,
		transportType,
		type,
		fee,
		unit,
		feeMax,
		feeMin,
		autoUpdate,
	};
};
