import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	Table,
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
	Icon,
	Button,
} from '@uturn/ui-kit';
import {
	getFilesIdDownloadUrl,
	useGetOrganizationsIdDocuments,
} from '@uturn/api/backoffice/v1';
import { DatesUtils } from '@uturn/utilities/browser';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';

type Props = {
	organizationId: number;
};

const Documents = ({ organizationId }: Props) => {
	const { data: documents } = useGetOrganizationsIdDocuments(organizationId, {
		query: {
			suspense: true,
			select: (data) => data.data.data,
		},
	});

	const downloadFile = async (id: number) => {
		const { data } = await getFilesIdDownloadUrl(id);
		if (!data.data) {
			return;
		}
		const response = await fetch(data.data.url);
		const blob = await response.blob();
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = data.data.name;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Card>
			<CardHeader>
				<CardTitle>Documents</CardTitle>
			</CardHeader>
			<CardContent>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>File</TableHead>
							<TableHead>Type</TableHead>
							<TableHead>Expiration date</TableHead>
							<TableHead />
						</TableRow>
					</TableHeader>
					<TableBody>
						{documents?.map((document) => (
							<TableRow key={document.id}>
								<TableCell>{document.name}</TableCell>
								<TableCell>{document.category}</TableCell>
								<TableCell>
									{document.expiresOn &&
										DatesUtils.dateStringToFormattedDate(document.expiresOn, {
											dateFormat: 'dd/MM/yyyy',
										})}
								</TableCell>
								<TableCell className="text-right">
									<Button
										onClick={() => downloadFile(document.id)}
										size="icon"
										variant="ghost"
									>
										<Icon icon={faDownload} />
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};
export default Documents;
